<!-- eslint-disable import/no-cycle -->
<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link
          class="brand-logo"
          :to="{name:'accueil'}"
        >
          <b-img
            :src="appLogoImage"
            width="40"
            height="40"
            alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Réinitialiser le mot de passe
        </b-card-title>
        <b-card-text class="mb-2">
          Votre nouveau mot de passe doit être différent
          des mots de passe précédemment utilisés.
        </b-card-text>

        <!-- form -->

        <validation-observer
          #default="{invalid}"
          ref="simpleRules"
        >
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="resetPassword"
          >

            <!-- password -->
            <b-form-group
              label="Nouveau mot de passe"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="mot de passe"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="form.password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmation de mot de passe"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmation de mot de passe"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="form.password_confirmation"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              size="sm"
              :disabled="invalid || process"
            >
              Changer votre mot de passe
              <b-spinner
                v-if="process"
                small
                label="Loading..."
                variant="light"
                class="ml-1"
              />
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Se connecter
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import {
  onMounted,
  reactive, ref,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BImg, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import useAuthenticate from '@/services/authentification/authService'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BImg,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  setup() {
    const {
      process, reset,
    } = useAuthenticate()
    const { appName, appLogoImage } = $themeConfig.app

    const token = ref('')
    onMounted(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      token.value = params.token
    })

    const form = reactive({
      token,
      password: '',
      password_confirmation: '',
    })
    const resetPassword = async () => {
      await reset(form)
    }
    return {
      form,
      resetPassword,
      process,
      reset,
      appName,
      appLogoImage,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
